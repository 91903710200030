<template>
  <v-app style="background-color: #f2f4f7">
    <Seo :key="$route.path + 'seo'" />
    <div>
      <router-view :key="$route.path + 'router'" />
    </div>
  </v-app>
</template>

<script>
import Seo from '@/components/Seo.vue'
export default {
  methods: {},
  watch: {
    '$route.path'() {
      this.$nextTick(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      })
    },
  },
  components: { Seo },
};
</script>
<style>
@import "./assets/styles.css";
@import "./styles/global.scss";
</style>
